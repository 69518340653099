import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import api from "../api/api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";

export default function Members() {
  const [activeMembers, setActiveMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const UserId = sessionStorage.getItem("UserID");
  const Password = sessionStorage.getItem("Password");
  const getActiveMembersData = async () => {
    try {
      setLoading(true);
      const requestData = {
        userid: UserId,
        password: Password,
        synceddatetime: "2023-01-24 11:57:34",
        FormCode: "201",
        ApiKey: "kavin",
        AppTypeNo: "3",
        AppVersion: "1.0.0",
        DbVersion: "10.4.1",
        DbSource: "W",
      };
      const result = await api.getActiveMembers(requestData);
      if (result && result.status === 200) {
        setActiveMembers(result.data.dashboard_count);
      } else {
        console.error("Unexpected API response format:", result);
      }
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getActiveMembersData();
  }, []);

  const viewButtonTemplate = (rowData) => {
    return (
      <Button
        label="View"
        className="viewButton"
      />
    );
  };
  return (
    <div className="container">
      <span className="heading-page mt-0">Members</span>
      {loading && (
        <div className="pro-spin">
          <ProgressSpinner
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#7265e2",
            }}
          />
        </div>
      )}
      <div className="card mt-2">
        <DataTable
          value={activeMembers}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="fld_name"
            header="Name"
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="fld_email"
            header="Email"
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="fld_contact_number"
            header="Contact Number"
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="fld_gender_name"
            header="Gender"
            style={{ width: "15%" }}
          ></Column>
          <Column
            field="fld_date_of_birth"
            header="DOB"
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="fld_height"
            header="Height"
            style={{ width: "10%" }}
          ></Column>
          <Column
            body={viewButtonTemplate}
            header="Actions"
            style={{ width: "15%" }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}
