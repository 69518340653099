// import React, { useRef, useState, useEffect } from "react";
// import { Menu } from "primereact/menu";
// import { TieredMenu } from "primereact/tieredmenu";
// import { Button } from "primereact/button";
// import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
// import Dashboard from "./../pages/Dashboard";
// import Members from "../pages/Members";
// import Leaderboard from "../pages/Leaderboard";
// import Reports from "../pages/Reports";

// const Main=()=> {
//   const menu = useRef(null);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [activeTab, setActiveTab] = useState("");

//   useEffect(() => {
//     setActiveTab(location.pathname);
//   }, [location.pathname]);
//   const clearSession = () => {
//     sessionStorage.clear();
//     navigate("/");
//   };

//   const displayContent = () => {
//     return (
//       <Routes>
//         <Route exact path="/dashboard" element={<Dashboard />} />
//         <Route exact path="/members" element={<Members />} />
//         <Route exact path="/leaderboard" element={<Leaderboard />} />
//         <Route exact path="/reports" element={<Reports />} />
//       </Routes>
//     );
//   };

//   const items1 = [
//     {
//       label: "Log out",
//       icon: "pi pi-sign-out",
//       command: () => {
//         clearSession();
//       },
//       // style: { fontFamily: '"Poppins", sans-serif' }
//     },
//   ];

// //   const SuperItems = [
// //     {
// //       label: (
// //         <p className={`m-0 pt-1 ${activeTab === "/main/dashboard" ? "active-text" : ""}`}>
// //           Dashboard
// //         </p>
// //       ),
// //       icon: activeTab === "/main/dashboard" ? (
// //         <i className="fa-solid fa-house" style={{ fontSize: "22px", color: "#5D5FEF", paddingRight: "10px" }} />
// //       ) : (
// //         <i className="pi pi-home" style={{ fontSize: "24px", paddingRight: "10px" }} />
// //       ),
// //       className: activeTab === "/main/dashboard" ? "active-tab" : "",
// //       command: () => {
// //         setActiveTab("/main/dashboard");
// //         navigate("/main/dashboard");
// //       },
// //     },
// //     {
// //       label: (
// //         <p className={`m-0 ${activeTab === "/main/members" ? "active-text" : ""}`}>
// //          Members
// //         </p>
// //       ),
// //       icon: activeTab === "/main/members" ? (
// //         <img src="/G__logo.png" style={{width:"26px" , marginRight:"10px" }} alt="google logo"/>
// //       ) : (
// //         <i className="pi pi-google" style={{ fontSize: "22px", paddingRight: "10px" }} />
// //       ),
// //       className: activeTab === "/main/members" ? "active-tab" : "",
// //       command: () => {
// //         setActiveTab("/main/members");
// //         navigate("/main/members");
// //       },
// //     },
// //     {
// //       label: (
// //         <p className={`m-0 ${activeTab === "/main/leaderboard" ? "active-text" : ""}`}>
// //        Leaderboard
// //         </p>
// //       ),
// //       icon: activeTab === "/main/leaderboard" ? (
// //         <i className="fa-solid fa-stethoscope" style={{ fontSize: "24px", color: "#5D5FEF", paddingRight: "10px" }} />
// //       ) : (
// //         <i className="fa-solid fa-stethoscope" style={{ fontSize: "24px", paddingRight: "10px" }} />
// //       ),
// //       className: activeTab === "/main/leaderboard" ? "active-tab" : "",
// //       command: () => {
// //         setActiveTab("/main/leaderboard");
// //         navigate("/main/leaderboard");
// //       },
// //     },
// //     {
// //       label: (
// //         <p className={`m-0 ${activeTab === "/main/reports" ? "active-text" : ""}`}>
// //           Reports
// //         </p>
// //       ),
// //       icon: activeTab === "/main/reports" ? (
// //         <i className="fa-solid fa-clipboard" style={{ fontSize: "25px", color: "#5D5FEF", paddingRight: "10px" }} />
// //       ) : (
// //         <i className="pi pi-clipboard" style={{ fontSize: "24px", paddingRight: "10px" }} />
// //       ),
// //       className: activeTab === "/main/reports" ? "active-tab" : "",
// //       command: () => {
// //         setActiveTab("/main/reports");
// //         navigate("/main/reports");
// //       },
// //     },
// //   ];
// const SuperItems = [
//   {
//     label: (
//       <p className={`m-0 pt-1 ${activeTab === "/main/dashboard" ? "active-text" : ""}`}>
//         Dashboard
//       </p>
//     ),
//     icon: activeTab === "/main/dashboard" ? (
//       <i className="pi pi-chart-pie" style={{ fontSize: "22px", color: "white", paddingRight: "10px" }} />
//     ) : (
//       <i className="pi pi-chart-pie" style={{ fontSize: "22px", color: "black", paddingRight: "10px" }} />
//     ),
//     className: activeTab === "/main/dashboard" ? "active-tab" : "",
//     command: () => {
//       setActiveTab("/main/dashboard");
//       navigate("/main/dashboard");
//     },
//   },
//   {
//     label: (
//       <p className={`m-0 ${activeTab === "/main/members" ? "active-text" : ""}`}>
//         Members
//       </p>
//     ),
//     icon: activeTab === "/main/members" ? (
//       <i className="pi pi-users" style={{ fontSize: "22px", color: "white", paddingRight: "10px" }} />
//     ) : (
//       <i className="pi pi-users" style={{ fontSize: "22px", color: "black", paddingRight: "10px" }} />
//     ),
//     className: activeTab === "/main/members" ? "active-tab" : "",
//     command: () => {
//       setActiveTab("/main/members");
//       navigate("/main/members");
//     },
//   },
//   {
//     label: (
//       <p className={`m-0 ${activeTab === "/main/leaderboard" ? "active-text" : ""}`}>
//         Leaderboard
//       </p>
//     ),
//     icon: activeTab === "/main/leaderboard" ? (
//       <i className="pi pi-crown" style={{ fontSize: "22px", color: "white", paddingRight: "10px" }} />
//     ) : (
//       <i className="pi pi-crown" style={{ fontSize: "22px", color: "black", paddingRight: "10px" }} />
//     ),
//     className: activeTab === "/main/leaderboard" ? "active-tab" : "",
//     command: () => {
//       setActiveTab("/main/leaderboard");
//       navigate("/main/leaderboard");
//     },
//   },
//   {
//     label: (
//       <p className={`m-0 ${activeTab === "/main/reports" ? "active-text" : ""}`}>
//         Reports
//       </p>
//     ),
//     icon: activeTab === "/main/reports" ? (
//       <i className="pi pi-file" style={{ fontSize: "22px", color: "white", paddingRight: "10px" }} />
//     ) : (
//       <i className="pi pi-file" style={{ fontSize: "22px", color: "black", paddingRight: "10px" }} />
//     ),
//     className: activeTab === "/main/reports" ? "active-tab" : "",
//     command: () => {
//       setActiveTab("/main/reports");
//       navigate("/main/reports");
//     },
//   },
// ];

// return (
//     <>
//     <div className="sidebar">
//       <div className="flex justify-content-center mt-5">
//         <img src="/Logo2.png" alt="logo" style={{ width: "180px" }} />
//       </div>
//       <div className="menu-container">
//         <Menu model={SuperItems} className="sidebarMenu mt-7 pl-4" />
//       </div>
//       <div className="sidebartieredmenu-container" style={{display: "flex", justifyContent: "center",  marginBottom: "15px"}}>
//         <TieredMenu model={items1} popup ref={menu} />
//         <Button
//           className="sidebartieredmenu pi pi-user"
//           onClick={(e) => menu.current.toggle(e)}
//         >
//           <span className="menuFont">Admin</span> <i className="pi pi-chevron-up" />
//         </Button>
//       </div>
//     </div>
//     <div className="cardStyle">
//       <div>{displayContent()}</div>
//     </div>
//   </>
//   );
// }
// export default Main;

import React, { useRef, useState, useEffect } from "react";
import { Menu } from "primereact/menu";
import { TieredMenu } from "primereact/tieredmenu";
import { Button } from "primereact/button";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Members from "../pages/Members";

const Main = () => {
  const menu = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);
  const clearSession = () => {
    sessionStorage.clear();
    // localStorage.removeItem('sessionId');
    navigate("/");
  };

  const displayContent = () => {
    return (
      <Routes>
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/members" element={<Members />} />
      </Routes>
    );
  };

  const items1 = [
    {
      label: "Log out",
      icon: "pi pi-sign-out",
      command: () => {
        clearSession();
      },
      // style: { fontFamily: '"Poppins", sans-serif' }
    },
  ];

  const SuperItems = [
    {
      label: (
        <p
          className={`m-0 pt-1 ${
            activeTab === "/main/dashboard" ? "active-text" : ""
          }`}
        >
          Dashboard
        </p>
      ),
      icon:
        activeTab === "/main/dashboard" ? (
          <i
            className="pi pi-chart-pie"
            style={{ fontSize: "22px", color: "#7265e2", paddingRight: "10px" }}
          />
        ) : (
          <i
            className="pi pi-chart-pie"
            style={{ fontSize: "24px", paddingRight: "10px" }}
          />
        ),
      className: activeTab === "/main/dashboard" ? "active-tab" : "",
      command: () => {
        setActiveTab("/main/dashboard");
        navigate("/main/dashboard");
      },
    },
    {
      label: (
        <p
          className={`m-0 ${
            activeTab === "/main/members" ? "active-text" : ""
          }`}
        >
          Members
        </p>
      ),
      icon:
        activeTab === "/main/members" ? (
          <i
            className="pi pi-users"
            style={{ fontSize: "22px", color: "#7265e2", paddingRight: "10px" }}
          />
        ) : (
          <i
            className="pi pi-users"
            style={{ fontSize: "22px", paddingRight: "10px" }}
          />
        ),
      className: activeTab === "/main/members" ? "active-tab" : "",
      command: () => {
        setActiveTab("/main/members");
        navigate("/main/members");
      },
    },
  ];
  return (
    <>
      <div className="sidebar">
        <div className="flex justify-content-center mt-5">
          <img src="/Logo2.png" alt="logo" style={{ width: "120px" }} />
        </div>
        <div className="menu-container">
          <Menu model={SuperItems} className="sidebarMenu mt-7 pl-4" />
        </div>
        <div
          className="sidebartieredmenu-container"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <TieredMenu model={items1} popup ref={menu} />
          <Button
            className="sidebartieredmenu pi pi-user"
            onClick={(e) => menu.current.toggle(e)}
          >
            <span className="menuFont">Administrator</span>{" "}
            <i className="pi pi-chevron-up" />
          </Button>
        </div>
      </div>
      <div className="cardStyle">
        <div>{displayContent()}</div>
      </div>
    </>
  );
};
export default Main;
