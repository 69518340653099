import React from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./App.css";
import Login from "./components/auth/Login";
import { Route, Routes } from "react-router-dom";
import Main from "./components/layout/Main";
import UserDashboard from "./components/pages/UserDashboard";

export default function App() {
  return (
    <div>
    <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/main/*" element={<Main />} />
        {/* <Route path="/userdashboard" element={<UserDashboard />} /> */}
      </Routes>
    </div>
  );
}
